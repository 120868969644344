import {createRouter, createWebHistory} from 'vue-router'
import dashboard from './routes/dashboard'
import auth from './routes/auth'
import profile from './routes/profile'
import company from './routes/company'
import journal from './routes/journal'
import receipt from './routes/receipt'
import contra from './routes/contra'
import payment from './routes/payment'
import hospital from './routes/hospital'
import chartAccount from './routes/chartAccount'
import other from './routes/other'
import accountSettings from './routes/accountSettings'
import report from './routes/report'
import proadvisor from './routes/proadvisor'
import inventory from './routes/inventory'
import companySettings from './routes/companySettings'
import contact from "@/router/routes/contact";
import deal from "@/router/routes/deal";
import campaign from "@/router/routes/campaign";
import business from "@/router/routes/business";
import user from "@/router/routes/user";
import role from "@/router/routes/role";
import procurementInventory from "@/router/routes/procurement/inventory";
import procurementFd6 from "@/router/routes/procurement/fd6";
import procurementProject from "@/router/routes/procurement/project";
import procurementBudget from "@/router/routes/procurement/budget";
import procurementRequisition from "@/router/routes/procurement/requisition";
import procurementVendorSelection from "@/router/routes/procurement/vendorSelection";
import procurementPurchaseOrder from "@/router/routes/procurement/purchaseOrder";
import procurementImportTracker from "@/router/routes/procurement/importTracker";
import procurementOrganizationSettings from "@/router/routes/procurement/organizationSettings";
import ngoPaymentVoucher from "@/router/routes/ngo/payment";
import datasheetExport from "@/router/routes/procurement/datasheet";
import npJournalVoucher from "@/router/routes/ngo/journal";
import npReceiptVoucher from "@/router/routes/ngo/receipt"
import npContraVoucher from "@/router/routes/ngo/contra";
import voucher from "@/router/routes/voucher";
import tdsVds from "@/router/routes/procurement/tdsVds";
import scmTagRegister from "@/router/routes/scm/tag-register";
import scmLandedCosting from "@/router/routes/scm/landed-costing";
import crm from "@/router/routes/crm/crm";

const routes = [
	...dashboard,
	...auth,
	...profile,
	...contact,
	...deal,
	...campaign,
	...company,
	...other,
	...contra,
	...receipt,
	...journal,
	...payment,
	...hospital,
	...voucher,
	...proadvisor,
	...inventory,
	...chartAccount,
	...accountSettings,
	...companySettings,
	...report,
	...user,
	...role,
	...business,
	...procurementInventory,
	...procurementFd6,
	...procurementProject,
	...procurementBudget,
	...procurementRequisition,
	...procurementVendorSelection,
	...procurementPurchaseOrder,
	...procurementImportTracker,
	...procurementOrganizationSettings,
	...ngoPaymentVoucher,
	...datasheetExport,
	...npJournalVoucher,
	...npReceiptVoucher,
	...npContraVoucher,
	...tdsVds,
	...scmTagRegister,
	...scmLandedCosting,
	...crm,
	{
		path: '/:pathMatch(.*)*',
		redirect: 'error-404',
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),

	routes,

	scrollBehavior(to, from, savedPosition) {
		// return savedPosition || new Promise((resolve) => {
		//   setTimeout(() => resolve({top: 0, behavior: 'smooth'}),  500)
		// })

		if (savedPosition) {
			return savedPosition;
		} else if (to.hash) {
			return {
				el: to.hash,
				behavior: 'smooth'
			}
		} else {
			return new Promise((resolve) => {
				setTimeout(() => resolve({top: 0, behavior: 'smooth'}), 500)
			})
		}
	},
});

router.beforeEach((to, from, next) => {
	window.document.title = to.meta && to.meta.title ? to.meta.title : 'miaccounts';
	if (to.matched.some(record => record.meta.requiresAuth)) {
		let token = localStorage.getItem('token');
		if (token) {
			next()
			return
		}
		next('/login')
	} else {
		next()
	}
});

export default router
