export default [
    {
        path: '/contacts',
        name: 'contact.user.index',
        component: () => import('@/views/page/Contact/HomeContact.vue'),
        meta: {
            requiresAuth: true,
            layout: 'contact'
        }
    },

    {
        path: '/contacts/my-profile',
        name: 'contact.myProfile.show',
        component: () => import('@/views/page/Contact/ContactMyProfile.vue'),
        meta: {
            requiresAuth: true,
            layout: 'contact'
        }
    },

    {
        path: '/contacts/create-profile',
        name: 'contact.profile.store',
        component: () => import('@/views/page/Contact/ContactProfileForm.vue'),
        meta: {
            requiresAuth: true,
            layout: 'contact'
        }
    },

    {
        path: '/contacts/profile/:contactId/details',
        name: 'contact.profile.details',
        component: () => import('@/views/page/Contact/ContactProfileDetails.vue'),
        meta: {
            requiresAuth: true,
            layout: 'contact'
        }
    },

    {
        path: '/contacts/filter',
        name: 'contact.filter',
        component: () => import('@/views/page/Contact/ContactFilter.vue'),
        meta: {
            requiresAuth: true,
            layout: 'contact'
        }
    },

    /*********************************************
    * Company Contact Routes
    *********************************************/

    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/page/:pageId',
        name: 'contacts-all',
        component: () => import('@/views/page/CompanyContact/HomeContact'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/supplier-contacts/:pageId',
        name: 'contacts-supplier',
        component: () => import('@/views/page/CompanyContact/HomeContact'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/employee-contacts/:pageId',
        name: 'contacts-employee',
        component: () => import('@/views/page/CompanyContact/HomeContact'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/employee-contacts/:pageId',
        name: 'contacts-customer',
        component: () => import('@/views/page/CompanyContact/HomeContact'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/member-contacts/:pageId',
        name: 'contacts-member',
        component: () => import('@/views/page/CompanyContact/HomeContact'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/contacts-create/:pageId',
        name: 'contacts-create',
        component: () => import('@/views/page/CompanyContact/ContactProfileForm'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/contacts-details/:contactId',
        name: 'company.contacts.details',
        component: () => import('@/views/page/CompanyContact/ContactProfileDetails'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/contact-register/:pageId',
        component: () => import('@/views/page/company/contact/register/Index.vue'),
        name: 'contact-register',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/add-contact/:pageId',
        component: () => import('@/views/page/company/contact/Add.vue'),
        name: 'add-contact',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/contact-profile/:pageId',
        component: () => import('@/views/page/company/contact/List.vue'),
        name: 'contact-profile',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/contact-import/:pageId',
        component: () => import('@/views/page/company/contact/contact-import/ContactImportList.vue'),
        name: 'contact-import',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/upload-contact/:pageId',
        component: () => import('@/views/page/company/contact/contact-import/UploadContact.vue'),
        name: 'upload-contact',
        meta: {
            requiresAuth: true
        }
    }
]
